import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At `}<a parentName="p" {...{
        "href": "https://coiled.io"
      }}>{`Coiled`}</a>{` we use a few hooks that run with `}<inlineCode parentName="p">{`pre-commit`}</inlineCode>{`. This means that before git commits your changes, these hooks will be run and report any failures.  Using `}<inlineCode parentName="p">{`pre-commit`}</inlineCode>{` is a great way to spot issues (like linting) before you do At `}<a parentName="p" {...{
        "href": "https://coiled.io"
      }}>{`Coiled`}</a>{` we use a few hooks that run with `}<inlineCode parentName="p">{`pre-commit`}</inlineCode>{`. This means that before git commits your changes, these hooks will be run and report any failures.  Using `}<inlineCode parentName="p">{`pre-commit`}</inlineCode>{` is a great way to spot issues (like linting) before you do your commits.`}</p>
    <p>{`Everything went fine when I set up my dev environment, the command `}<inlineCode parentName="p">{`pre-commit install`}</inlineCode>{` throw no errors when I ran it. But on my first commit, I got an error with `}<inlineCode parentName="p">{`pyright`}</inlineCode>{` that apparently couldn't find the `}<inlineCode parentName="p">{`worker_threads`}</inlineCode>{` module.`}</p>
    <h2 {...{
      "id": "the-problem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-problem",
        "aria-label": "the problem permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Problem`}</h2>
    <p>{`Pyright it's a type checker for Python created by Microsoft. This was the first time I was using `}<inlineCode parentName="p">{`pyright`}</inlineCode>{` so I have never encountered the error. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pyright..................................................................Failed
- hook id: pyright
- exit code: 1

internal/modules/cjs/loader.js:638
    throw err;
    ^

Error: Cannot find module 'worker_threads'
    at Function.Module._resolveFilename (internal/modules/cjs/loader.js:636:15)
    at Function.Module._load (internal/modules/cjs/loader.js:562:25)
    at Module.require (internal/modules/cjs/loader.js:692:17)
    at require (internal/modules/cjs/helpers.js:25:18)
    at Object.5013 (/home/fabiorosado/.cache/pre-commit/repov31novgl/node_env-system/lib/node_modules/pyright/dist/pyright.js:1:597)
    at s (/home/fabiorosado/.cache/pre-commit/repov31novgl/node_env-system/lib/node_modules/pyright/dist/pyright.js:1:723)
    at Object.4221 (/home/fabiorosado/.cache/pre-commit/repov31novgl/node_env-system/lib/node_modules/pyright/dist/pyright-internal.js:1:204756)
    at s (/home/fabiorosado/.cache/pre-commit/repov31novgl/node_env-system/lib/node_modules/pyright/dist/pyright.js:1:723)
    at Object.8408 (/home/fabiorosado/.cache/pre-commit/repov31novgl/node_env-system/lib/node_modules/pyright/dist/pyright-internal.js:1:158250)
    at s (/home/fabiorosado/.cache/pre-commit/repov31novgl/node_env-system/lib/node_modules/pyright/dist/pyright.js:1:723)

tslint...............................................(no files to check)Skipped
tsc..................................................(no files to check)Skipped
`}</code></pre>
    <p>{`This error made me unable to use pre-commit, which is a bummer because I kept forgetting to run black to automatically check and lint my code. `}</p>
    <p>{`After exploring online, it seems that it might be a node issue, I've tried using Node Version Manager to update node to the latest version (15.3.0), but that didn't work. So, I tried to install the latest node version manually, but that didn't work as well.`}</p>
    <h2 {...{
      "id": "the-solution",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-solution",
        "aria-label": "the solution permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The solution`}</h2>
    <p>{`After going through a few StackOverflow questions and Github issues, I came across this GitHub issue created on the electron repository - `}<a parentName="p" {...{
        "href": "https://github.com/electron/electron/issues/18540"
      }}>{`Unable to use NodeJs worker-threads module`}</a>{`.`}</p>
    <p>{`Running this command will be enough to fix the issue.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`export NODE\\_OPTIONS=--experimental-worker
`}</code></pre>
    <p>{`Now I can run `}<inlineCode parentName="p">{`git commit`}</inlineCode>{`, and my pre-commit will run without throwing any errors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      